<template>
    <v-dialog v-model="showInvoicePreview">
      <v-card>

        <v-card-title>
          <span class="text-h5 font-weight-bold">Credit Note Preview</span>
  
          <v-spacer></v-spacer>
         
  
          <v-btn
            icon
            color="primary"
            @click="resetForm(), (showInvoicePreview = false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-divider></v-divider>
  
        <v-card-text>
          <v-row>

          
            <v-col cols="12" v-for="(invoice, i) in invoiceList" :key="i">
              <v-row>
              
                <v-col cols="12">
                  <CreditInvoiceTemplate :invoiceDetails="invoice" />
                </v-col>
  
                <v-col cols="12" v-if="i != invoiceList.length - 1">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import CreditInvoiceTemplate from "@/components/General/CreditInvoiceTemplate.vue";
  export default {
    components: { CreditInvoiceTemplate },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      invoiceObject: {
        type: Object,
        required: true,
      },
    },
    watch: {
      value(val) {
        if (val) {
          this.getCreditNotePreviewDetails();
        }
      },
    },
    data() {
      return {
        invoiceList: [],
  
        daHeaders: [
          {
            text: "Delivery Advice No.",
            value: "da_no",
          },
          {
            text: "Container Type",
            value: "da_container_details.da_container_type",
          },
          {
            text: "Container Count",
            value: "da_container_details.da_container_count",
          },
          {
            text: "Container No.",
            value: "da_container_details.da_container_no",
          },
          {
            text: "Created At",
            value: "created",
          },
          {
            text: "Movement Date",
            value: "movement_date",
          },
          {
            text: "BOE",
            value: "boe",
          },
          {
            text: "Source",
            value: "source_location_code",
          },
          {
            text: "Mid",
            value: "mid_location_code",
          },
          {
            text: "Destination",
            value: "destination_location_code",
          },
          {
            text: `Rate (${this.$globals.currency})`,
            value: "rate",
          },
          {
            text: `Tax Amount (${this.$globals.currency})`,
            value: "tax_amount",
          },
          {
            text: `Additional Charges(${this.$globals.currency})`,
            value: "da_additional_charges",
          },
        ],
      };
    },
    computed: {
      showInvoicePreview: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
    },
    methods: {
      resetForm() {
        this.invoiceList = [];
        this.$emit("refreshList");
      },
      getCreditNotePreviewDetails() {
        this.$bus.$emit("showLoader", true);
  
        let payload = {
        
          ...this.invoiceObject,
          delivery_advices: this.invoiceObject.delivery_advices.map((da) => da),
        };
        
       
  
        this.$api.deliveryAdvice
          .generateCreditNotePreviewFromDA(payload)
          .then((res) => {
            this.invoiceList = res.data;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      },
    },
  };
  </script>
  